import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Components/Login'
import Dashboard from './Components/Dashboard';
import AllCards from './Components/AllCards';
import GamePage from './Components/GamePage';
import AdminGames from './Components/myGames';
import AdminStats from './Components/Stats';
import Navbar from './Components/NavBar';
import Report from './Components/Reports';
import AdminTable from './Components/AllStats';
import Zola from './Components/Zola';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/all-cards" element={<AllCards />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/game-in-progress" element={<GamePage />} />
        {/* <Route path="/my-games" element={<AdminGames />} />
        <Route path="/my-stats" element={<AdminStats />} /> */}
        <Route path="/reports" element={<Report />} />
        <Route path="/pyt/axs" element={<AdminTable />} />
        <Route path="/superadmin/zola" element={<Zola />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
