import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, Form } from 'react-bootstrap';

const Zola = () => {
  const [admins, setAdmins] = useState([]);
  const [editingAdminId, setEditingAdminId] = useState(null);
  const [addAmount, setAddAmount] = useState('');
  const [showRegistration, setShowRegistration] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [initialWallet, setInitialWallet] = useState('');
  const API = process.env.REACT_APP_API;
  const [passcode, setPasscode] = useState('');
  const [isPasscodeCorrect, setIsPasscodeCorrect] = useState(false);
  const [superZolaWallet, setSuperZolaWallet] = useState(0);
  const [refreshData, setRefreshData] = useState(false);
  
  useEffect(() => {
    fetchAdmins();
  }, [refreshData]);

  useEffect(() => {
    fetchSuperZolaWallet();
  }, [admins]);

  const handleRefreshClick = () => {
    setRefreshData(!refreshData);
  };
  
  const handlePasscodeSubmit = (event) => {
    event.preventDefault();
    if (passcode === 'zola8563') {
      setIsPasscodeCorrect(true);
      console.log(admins)
    } else {
      alert('Incorrect passcode. Please try again.');
    }
  };

  const fetchSuperZolaWallet = () => {
    const superZola = admins.find((admin) => admin.username === 'zola-super');
    if (superZola) {
      setSuperZolaWallet(superZola.wallet);
    } else {
      setSuperZolaWallet(0);
    }
  };


  const fetchAdmins = async () => {
    try {
      const response = await axios.get(`${API}/admin/naan/all/protected`);
      const filteredAdmins = response.data.filter((admin) =>
        admin.username.startsWith('zola-')
      );
      setAdmins(filteredAdmins);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const handleEditWallet = (adminId) => {
    setEditingAdminId(adminId);
    setAddAmount('');
  };

  const handleAddAmountChange = (event) => {
    setAddAmount(event.target.value);
  };

  const addToWallet = async (adminId) => {
    try {
      const currentAdmin = admins.find((admin) => admin._id === adminId);
      const updatedWallet = currentAdmin.wallet + parseFloat(addAmount);
      await axios.put(`${API}/admin/${adminId}/wallet`, { wallet: updatedWallet });

      const superZola = admins.find((admin) => admin.username === 'zola-super');
      const updatedSuperZolaWallet = superZola.wallet - parseFloat(addAmount);
      await axios.put(`${API}/admin/${superZola._id}/wallet`, { wallet: updatedSuperZolaWallet });

      await fetchAdmins();
      setAdmins(
        admins.map((admin) => (admin._id === adminId ? { ...admin, wallet: updatedWallet } : admin))
      );
      setEditingAdminId(null);
      setAddAmount('');
    } catch (error) {
      console.error('Error updating admin wallet:', error);
    }
  };


  const getTodaysProfit = (admin) => {
    const today = new Date().toISOString().slice(0, 10);
    console.log(today)
    const todayStat = admin.statsByPeriod.find((stat) => stat.date.startsWith(today));
    return todayStat ? todayStat.profit : 0;
  };


  const getMaxProfit = (admin) => {
    const maxProfit = Math.max(...admin.statsByPeriod.map((stat) => stat.profit));
    return isFinite(maxProfit) ? maxProfit : 0;
  };

  const getTodaysTotalProfit = () => {
    return admins.reduce((total, admin) => total + getTodaysProfit(admin), 0);
  };

  const getMonthlyProfit = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const monthlyStats = admins.flatMap((admin) =>
      admin.statsByPeriod.filter((stat) => {
        const statDate = new Date(stat.date);
        return statDate.getMonth() === currentMonth && statDate.getFullYear() === currentYear;
      })
    );

    const totalProfit = monthlyStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit;
  };

  const getTotalProfitToDate = () => {
    const totalProfit = admins.reduce((sum, admin) => sum + admin.totalProfit, 0);
    return totalProfit;
  };


  if (!isPasscodeCorrect) {
    return (
      <div className="container">
        <h2 className="my-4">Admin Dashboard</h2>
        <Form onSubmit={handlePasscodeSubmit}>
          <Form.Group controlId="passcode">
            <Form.Label>Passcode</Form.Label>
            <Form.Control
              type="password"
              value={passcode}
              onChange={(e) => setPasscode(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="mt-4 d-flex flex-row">
          <h4 className='mx-2'>Super Zola's Wallet: {superZolaWallet}</h4>
          <button onClick={handleRefreshClick} className="btn btn-primary">
            Refresh
          </button>
      </div>
      <div className="m-2 d-flex">
        <button className="btn btn-dark">Today's Total Profit: {getTodaysTotalProfit()}</button>
        <button className="btn btn-dark">Monthly Profit: {getMonthlyProfit()}</button>
        <button className="btn btn-dark mx-2">Total Profit to Date: {getTotalProfitToDate()}</button>
      </div>

      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Username</th>
            <th>Total Profit</th>
            <th>Wallet</th>
            <th>Stats by Period</th>
            <th>Today's Profit</th>
            <th>Max Profit to Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {admins.map((admin) => (
            <tr key={admin._id}>
              <td>{admin.username}</td>
              <td>{admin.totalProfit}</td>
              <td>{admin.wallet}</td>
              <td>
                <details>
                  <summary className="btn btn-sm btn-info">View Stats</summary>
                  <table className="table table-bordered mt-2">
                    <thead>
                      <tr>
                        <th>Period</th>
                        <th>Profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {admin.statsByPeriod.map((stat, index) => (
                        <tr key={index}>
                          <td>{new Date(stat.date).toLocaleDateString()}</td>
                          <td>{stat.profit}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </details>
              </td>
              <td>{getTodaysProfit(admin)}</td>
              <td>{getMaxProfit(admin)}</td>
              <td>
                {admin.username !== 'zola-super' && (
                  editingAdminId === admin._id ? (
                    <div className="input-group mt-1">
                      <input
                        type="number"
                        value={addAmount}
                        onChange={handleAddAmountChange}
                        className="form-control"
                        placeholder="Add amount"
                      />
                      <button onClick={() => addToWallet(admin._id)} className="btn btn-primary" disabled={addAmount < 10000} >
                        Add
                      </button>
                      <button onClick={() => setEditingAdminId(null)} className="btn btn-secondary">
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <button onClick={() => handleEditWallet(admin._id)} className="btn btn-warning btn-sm">
                      Edit
                    </button>
                  )
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Zola;