import React from 'react';
import Modal from 'react-modal';

const NewFeatureModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="New Feature"
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#fff',
          padding: '20px',
          borderRadius: '4px',
          maxWidth: '400px',
          width: '100%',
        },
        overlay: {
          backgroundColor: 'rgba(0, 1, 0, 0.5)',
          zIndex: 1000,
        },
      }}
    >
      <h2>የመጨረሻውን ጨዋታ ቁጥሮች ያቆዩ!</h2>
      <p>
      በቀላሉ ለመምረጥ የሚፈልጉትን ቁጥሮች ላይ ጠቅ ያድርጉ እና ለሚቀጥለው ጨዋታዎ ይታወሳሉ.
      ከዚህ ቀደም የተመረጡ ቁጥሮችዎን እንደገና ለመጫን ከፈለጉ ፣ አዲሱን ምልክት ጠቅ ያድርጉ
      </p>
      <button onClick={onClose}>Close</button>
    </Modal>
  );
};

export default NewFeatureModal;