import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AdminStats from './Stats';
import AllCards from './AllCards';
import AdminGames from './myGames';

const Report = () => {
  const [activeComponent, setActiveComponent] = useState('AdminGames');
  const navigate = useNavigate(); // Initialize the navigate function
 
  const renderComponent = () => {
    switch (activeComponent) {
      case 'AdminStats':
        return <AdminStats />;
      case 'AllCards':
        return <AllCards />;
      case 'AdminGames':
        return <AdminGames />;
      default:
        return null;
    }
  };

  const handleNavClick = (componentName) => {
    setActiveComponent(componentName);
  };

  const handleBack = () => {
    navigate('/dashboard'); // Use navigate to go back to the dashboard
  };

  const displayName = window.location.href.includes('power') ? 'Haimanot' : 'Shashemene';

  return (
    <div style={{ display: 'flex', minHeight: '100vh', backgroundColor: '#222222' }}>
      <div
        style={{
          width: '200px',
          backgroundColor: '#333',
          color: '#fff',
          padding: '20px',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '5px',
        }}
        className='mx-5 my-5 rounded-3'
      >
        <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>Dashboard</h2>
        <p style={{ fontSize: '16px', marginBottom: '20px' }}>Welcome back</p>
         <div className='d-flex'>
            <div
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: '#ccc',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <img
                src="https://nexusbingo.com/images/avatar.jpg"
                alt="Profile"
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundColor: '#fff'
                }}
              />
            </div>
            <h6 className='m-2'>{displayName}</h6>
            </div>
        <div className='d-flex flex-column mt-4'>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li style={{ marginBottom: '20px' }}>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => handleNavClick('AdminGames')}
              >
                Dashboard
              </button>
            </li>
            <li style={{ marginBottom: '20px' }}>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => handleNavClick('AllCards')}
              >
                Board
              </button>
            </li>
            <li>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => handleNavClick('AdminStats')}
              >
                Report
              </button>
            </li>
          </ul>
        </div>
        <button className="btn btn-secondary" onClick={handleBack}>Back</button> {/* Use handleBack to navigate back */}
      </div>
      <div style={{ marginLeft: '220px', padding: '20px', width: 'calc(100% - 220px)' }}>
        {renderComponent()}
      </div>
    </div>
  );
};

export default Report;
